import * as React from "react";
import { Link } from "gatsby";
import {
  cardWrapper,
  cardCover,
  cardTextDiv,
  cardTitle,
  cardSubtitle,
} from "./Card.module.css";

const Card = ({ imgComp, pageLink, title, subTitle }) => {
  return (
    <div className={cardWrapper}>
      {imgComp()}
      <Link to={pageLink}>
        <div className={cardCover}>
          <div className={cardTextDiv}>
            <p className={cardTitle}>{title}</p>
            <p className={cardSubtitle}>{subTitle}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
