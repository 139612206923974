import * as React from "react";
import Layout from "../components/Layout";
import Card from "../components/Card";
import { StaticImage } from "gatsby-plugin-image";

// markup
const IndexPage = () => {
  // These img components were created this way
  // because you can't use outer props to StaticImage
  // Reference: https://stackoverflow.com/questions/66759909/how-to-pass-a-path-of-image-as-a-prop-in-gatsby-in-gatsby-plugin-image
  const imgFolia = () => (
    <StaticImage
      alt="Cover image"
      src="../images/foliaCardImg.png"
      className=".cardImg"
    />
  );
  const imgSpirali = () => (
    <StaticImage
      alt="Cover image"
      src="../images/spiraliCardImg.png"
      className=".cardImg"
    />
  );
  return (
    <Layout pageTitle="" isHome={true}>
      <Card
        imgComp={imgFolia}
        pageLink={"/folia"}
        title={"Folia"}
        subTitle={"Long-form algorithm"}
      />
      <Card
        imgComp={imgSpirali}
        pageLink={"/spirali"}
        title={"Spirali"}
        subTitle={"Long-form algorithm"}
      />
    </Layout>
  );
};

export default IndexPage;
